import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import { SectionTitle } from "../../components/SectionTitle";
import { SNSLink } from "../../components/SNSLink";
import { MemberCard, MemberProfile } from "../../components/MemberCard";
import MediaQuery from "react-responsive";
import {Helmet} from "react-helmet";

const ImageWidthPC = "w_500";
const ImageWidthSP = "w_300";

interface AboutProps {}

const About: React.FC<AboutProps> = ({}) => {
  const MemberProfileList: MemberProfile[] = [
    {
      imagePath: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1588143352/web/Member_TakayukiMiura_01.gif`,
      imagePathSP: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1588143352/web/Member_TakayukiMiura_01.gif`,
      name: "TAKAYUKI MIURA",
      nameKana: "タカユキ　ミウラ",
      role: "映像ディレクター",
      description: [
        "映像制作プロダクションで映像作品のVFX合成に従事し、数年後にフリーランスとして独立。",
        "企画から撮影、編集までをワンストップで行うために撮影機材の充実に務める。ゴジラ大好き。ゲームやりすぎ。"
      ]
    },
    {
      imagePath: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1588143352/web/Member_HironoriSano_01.gif`,
      imagePathSP: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1588143352/web/Member_HironoriSano_01.gif`,
      name: "HIRONORI SANO",
      nameKana: "ヒロノリ　サノ",
      role: "映像ディレクター",
      description: [
        "ミウラ氏と同じ制作プロダクションにてアニメ制作に従事し、数々の制作会社での経験を経て、フリーランスとして独立。",
        "3DCGを本業としていたが、実写撮影やアニメーションにハートを奪われつつある。干し芋大好き。フルーツも好き。"
      ]
    },
    {
      imagePath: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1588143352/web/Member_MemeMechan_01.gif`,
      imagePathSP: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1588143352/web/Member_MemeMechan_01.gif`,
      name: "MEME MECHAN",
      nameKana: "メメ　メチャン",
      role: "イラストレーター / 声優・ナレーター",
      description: [
        "多彩なテイストを描きあげる孤高のイラストレーター。",
        "そのポップで明るい元気印ボイスで、広告系動画を中心に声優・ナレーターとしても活躍している。",
        "サンリオ大好き。"
      ]
    },
    {
      imagePath: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1588430515/web/MasatoshiSekino_ckjh4v.gif`,
      imagePathSP: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1588430515/web/MasatoshiSekino_ckjh4v.gif`,
      name: "MASATOSHI SEKINO",
      nameKana: "セッキー　チャン",
      role: "俳優 / タレントキャスティング",
      description: [
        "日本を代表する名バイプレイヤー。",
        "数々の名作映画やドラマに出演し活躍するとともに、チームの制作案件ではタレントキャスティング業務も行う。",
        "三軒茶屋中心の呑み歩き。鍛えてないのになぜかマッチョ。"
      ]
    },
    {
      imagePath: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1588494544/web/TakeshiKameyama_wkx9qn.gif`,
      imagePathSP: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1588494544/web/TakeshiKameyama_wkx9qn.gif`,
      name: "TAKESHI KAMEYAMA",
      nameKana: "タケシ　カメヤマ",
      role: "システムエンジニア",
      description: [
        "数社での経験を経て、フリーのシステムエンジニアとして独立。",
        "大学時代に過ごした宇都宮の影響か、奇妙な訛り言葉を話すが、あくなき学習意欲と旺盛な食欲を力に変えて、複雑化を増す社会の荒波に立ち向かう。島らっきょとチャンジャ。"
      ]
    }
  ];

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - About`}</title>
      </Helmet>

      <Container>
        <SNSLink />

        <Header>
          <p>映像制作チーム mogFlowts（モグフロウツ）です。</p>
          <p>
            活動の指針を記した「情熱の戦士プログラム」をガイドラインとし、
            ハートに呼びかける作品づくりを心掛けております。
          </p>

          <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
            <img
              alt={"aboutImg"}
              src={
                "https://res.cloudinary.com/mogflowts/image/upload/w_250/v1588573964/web/ShigetaSan_xvkskn.gif"
              }
            />
          </MediaQuery>
        </Header>

        <Main>
          <SectionTitle>チームスタッフ</SectionTitle>

          <Body>
            {MemberProfileList.map((item, index) => {
              return <MemberCard key={index} profile={item} />;
            })}
          </Body>
        </Main>
      </Container>
    </Layout>
  );
};

export default About;

const Container = styled.div`
  margin: 0 auto;
  width: 100vw;

  color: ${theme.colors.mainTextColor};

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 15px 80px;
    width: ${theme.width.main};
  }
`;

const Header = styled.div`
  padding: 15px;

  font-weight: 600;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 100px 0 300px;
    padding: 40px;
    font-size: ${theme.fontSizes[1]};

    & > p {
      font-size: ${theme.fontSizes[3]};
    }

    & > img {
      margin-left: auto;
      display: flex;
    }
  }
`;

const Main = styled.div`
  padding: 16px 15px 0;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 16px 0 0;
  }
`;

const Body = styled.div`
  & > div {
    margin: 28px 0;
  }
`;
