import * as React from "react";
import styled from "styled-components";
import { theme } from "../theme";
import {
  SNS_FACEBOOK_URL,
  SNS_INSTAGRAM_URL,
  SNS_TWITTER_URL,
  SNS_YOUTUBE_URL,
} from "../../config/constans";

interface SNSLinkProps {}

const SNSLink: React.FC<SNSLinkProps> = ({}) => {
  return (
    <Container>
      <a href={SNS_YOUTUBE_URL} target={"_blank"} rel="noopener noreferrer">
        <i className="fab fa-youtube fa-2x" />
      </a>

      <a href={SNS_TWITTER_URL} target={"_blank"} rel="noopener noreferrer">
        <i className="fab fa-twitter fa-2x" />
      </a>

      {/*<a href={SNS_FACEBOOK_URL} target={"_blank"} rel="noopener noreferrer">*/}
      {/*  <i className="fab fa-facebook fa-2x" />*/}
      {/*</a>*/}

      <a href={SNS_INSTAGRAM_URL} target={"_blank"} rel="noopener noreferrer">
        <i className="fab fa-instagram fa-2x" />
      </a>
    </Container>
  );
};

export { SNSLink };

const Container = styled.div`
  margin: 15px auto 0;
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  color: ${theme.colors.grey60};

  i {
    margin: 0 10px;
    cursor: pointer;
  }

  a,
  a:visited {
    text-decoration: none;
    color: ${theme.colors.grey60};
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 15px auto 0;
    width: ${theme.width.main};
  }
`;
