import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import MediaQuery from "react-responsive";

interface MemberCardProps {
  profile: MemberProfile;
}

export type MemberProfile = {
  imagePath: string;
  imagePathSP: string;
  name: string;
  nameKana: string;
  role: string;
  description: string[];
};

const MemberCard: FC<MemberCardProps> = ({ profile }) => {
  return (
    <>
      {/* SP */}
      <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
        <ContainerSP>
          <Image>
            <img alt={"memberProfile"} src={profile.imagePathSP} />
          </Image>

          <Main>
            <div>
              <h2>{profile.name}</h2>
              <p>{profile.nameKana}</p>
            </div>

            <div>{profile.role}</div>

            <ul>
              {profile.description.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </Main>
        </ContainerSP>
      </MediaQuery>

      {/* PC */}
      <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
        <Container>
          <Image>
            <img alt={"memberProfile"} src={profile.imagePath} />
          </Image>

          <Main>
            <div>
              <h2>{profile.name}</h2>
              <p>{profile.nameKana}</p>
            </div>

            <div>{profile.role}</div>

            <ul>
              {profile.description.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </Main>
        </Container>
      </MediaQuery>
    </>
  );
};

export { MemberCard };

const ContainerSP = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Image = styled.div`
  width: 100%;
  display: flex;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: auto;
    display: inherit;
  }

  & > img {
    margin: 24px auto;
    max-width: 500px;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      margin: 0;
      width: 500px;
    }
  }
`;

const Main = styled.div`
  h2 {
    font-size: ${theme.fontSizes[1]};
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    margin-bottom: 8px;

    :last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    h2 {
      margin: 0;
      font-size: ${theme.fontSizes[2]};
    }

    p {
      margin: 0;
    }
  }

  // name
  & > :nth-child(1) {
    width: 100%;
    text-align: center;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      width: auto;
      text-align: left;
    }
  }

  // role
  & > :nth-child(2) {
    margin: 32px 0;
    text-decoration: underline;

    text-align: center;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      text-align: left;
    }
  }
`;

// const Main = styled.div`
//   h2 {
//     margin: 0;
//   }
//
//   p {
//     margin: 0;
//   }
//
//   // name
//   & > :nth-child(1) {
//     margin: 32px 0;
//   }
//
//   // role
//   & > :nth-child(2) {
//     margin: 32px 0;
//
//     text-decoration: underline;
//   }
// `;
